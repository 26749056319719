import React from 'react'
import { Tooltip } from "./Tooltip";

const Socials = ({socialStyle}) =>  {

    return (
        <ul className={socialStyle}>
            <li className="social-icons-instagram">
                <Tooltip text="Instagram" placement="top">
                    <a
                    href="https://instagram.com/jvrmed"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <i className="fab fa-instagram" />
                    </a>
                </Tooltip>
            </li>
            <li className="social-icons-linkedin">
                <Tooltip text="LinkedIn" placement="top">
                    <a
                    href="https://www.linkedin.com/in/jvrmed/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <i className="fab fa-linkedin" />
                    </a>
                </Tooltip>
            </li>
        </ul>
    );
};

export default Socials;